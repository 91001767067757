<template>
  <div>
    <div class="Section" v-wechat-title="this.clickVal+ '-3A医药城'">
      <div class="breadcrumbNav">
        <span class="navName">
        <router-link to="/home"><a href="#" style="color:#000">首页 > </a></router-link>  
        </span>
        <div class="activeNav">
          <span>
            {{ clickVal }}
            <!-- <router-link to="/home"
            >{{ clickVal }} </router-link> -->
          </span>
        </div>
      </div>
     
      <div class="goodsList" v-if="searchProducts!=[]">
        <div v-if="total==0">
          <p class="NullBox">暂无商品</p>
        </div>
        <div v-else> 
             <shopItem
              v-for="(item, index) in searchProducts"
              :key="index"
              class="goods"
              :info="item"
            ></shopItem>
            <div class="clear"></div>
            <p class="msgDes" @click="handleScroll"><i :class="finished?'':'el-icon-bottom'"></i>{{msgDes}}</p>
        </div>
      </div>
      <div class="goodsList" v-else>
         <p class="NullBox">敬请期待</p>
      </div>
      <!-- <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
const shopItem = () => import("components/index/shopItem.vue");
import {getTagIndexPagesById} from "api/product.js";
export default {
  name: "TagIdList",
   inject:['reload'],
  data() {
    return {
       clickVal:'', //  面包屑 初始，一级分类选中值为空
      clickID: '',    //一级分类ID 
      searchProducts: [], //初始商品数据

       parmesData: {}, //初始搜索值传参
      total: 0,
      current: 1, //当前页
      size: 10, //每页数量
      msgDes:'向下滑动/点击继续浏览商品',
      loading: false,
      finished: false,
    };
  },
  computed: {},
  created() {
    // this.getActivityModuleList()
    this.changeType();
  },
  methods: {
        //  首页根据标签获取列表页
        getActivityModuleList(datas){
            // 显示loading
            this.$loading({ fullscreen: true ,background:'#ffffff45'})
                getTagIndexPagesById(datas).then(res=>{
                    const records = res.data.data.productVOPage.records;
                     this.clickVal = res.data.data.tagName;
                    this.clickID=res.data.data.id
                    // this.searchProducts = records;
                    // this.total = Number(res.data.data.productVOPage.total);
                    if(this.parmesData.current==1){
                      this.searchProducts=[]
                    }
                    records.forEach(item => {
                      this.searchProducts.push(item)
                    });
                    console.log('当前数据数',this.searchProducts)

                    this.total = Number(res.data.data.productVOPage.total);
                        // 关闭loading
                    this.$loading().close();
                })
        },

   handleCurrentChange(val) {
      this.current = val;
      this.parmesData.tagId=this.$route.query.tagId
      this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
       this.getActivityModuleList(this.parmesData);
    },
    changeType() {
      this.parmesData.tagId=this.$route.query.tagId
      this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量

      this.getActivityModuleList(this.parmesData)
      
    },
    handleScroll() {  
        this.loading = true
        this.finished = false
        this.msgDes="点击继续浏览商品"
        console.log('当前页数',this.parmesData.current)
        console.log('this.total',this.total)
        console.log('总页数',parseInt(this.total/10))
        if (this.parmesData.current <= parseInt(this.total/10)) {
            this.parmesData.current += 1;// 页数+1
            console.log('加载页数', this.parmesData.current)
            console.log(this.parmesData)
            // 重新加载数据
            this.getActivityModuleList(this.parmesData)
        }
        // 数据全部加载完成
        if (this.parmesData.current > parseInt(this.total/10)) {

          this.loading = false
          this.finished = true
          if(this.total!==0){
            setTimeout(() => {
              this.msgDes="我也是有底线的"
            }, 1000);
          }
        }
      
    }  
   
  },
   watch: {
      //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.type": function() {
        this.reload();
    },
  },
  components: {
    shopItem
  },

  mounted() {

    const _this = this
    document.body.onscroll = function () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight

      _this.finished = false

      // 滚动条到底部的条件
      if (scrollTop + windowHeight === scrollHeight) {
        _this.loading = true
        _this.msgDes="向下滑动继续浏览商品"
        console.log('_this.page',_this.parmesData.current)
        console.log('_this.total',parseInt(_this.total/10))
        if (_this.parmesData.current <= parseInt(_this.total/10)) {
            _this.parmesData.current += 1;// 页数+1
            // console.log('加载页数', _this.parmesData.current)
            // 重新加载数剧
            _this.getActivityModuleList(_this.parmesData);
        }
        // 数据全部加载完成

        if (_this.parmesData.current > parseInt(_this.total/10)) {
          _this.loading = false
          _this.finished = true
          if(_this.total!==0){
            setTimeout(() => {
              _this.msgDes="我也是有底线的"
            }, 1000);
          }
        }
      }
    }




  },
  updated() {},
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 1200px;
  margin: 10px auto 0;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ff9999;
          color: #ff9999;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: #F43C38;
            }
        }
        li.active {
          color: #F43C38;
        }
      }
    }
  }

//   .filterUl {
//     padding: 8px 15px;
//     margin-bottom: 17px;
//     border: 1px solid #eeeeee;
//     box-sizing: content-box;
//     background-color: #ffffff;
//     height: 20px;
//     line-height: 20px;
//     width: 100%;
//     float: left;
//     li.active {
//       color: #ff9999;
//     }
//     li {
//       float: left;
//       margin-right: 40px;
//       color: #666666;
//       font-size: 12px;
//       position: relative;
//         cursor: pointer;
//             &:hover{
//                 color: #F43C38;
//                 .el-checkbox{
//                   color: #F43C38;
//                 }
               
//             }
//       i {
//         font-weight: bolder;
//         font-size: 14px;
//          color: #666666;
//         &.active{
//            color: #F43C38;
//         }
//         &.iconTop{
//             position:absolute;
//             right: -18px;
//             top: 0;
//         }
//         &.iconBottom{
//             position:absolute;
//             right: -18px;
//             bottom: 0;
//         }
//       }
//     }
//   }
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    width: 100%;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
    .NullBox{
      font-size: 30px;
      text-align: center;
      width: 100%;
      line-height: 300px;
    height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E复盛公%3C/text%3E%3C/svg%3E");
      }
  }
  .clear{
    clear: both;
  }
  .msgDes{
    width: 100%;
    color: #666666;
    line-height:60px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
